import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import ContactTwo from "@/components/contact-two";
import ServiceDetails from "@/components/service-details";
import { Helmet as Head } from "react-helmet";

const ServiceDetailsPage = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Service Details">
        <Head>
          <title>Bedbug Extermination | Nifixeci</title>
          <meta
            name="description"
            content="For your bedbug issues, Nifixeci offers detailed and efficient pest
            extermination solutions."
          />
        </Head>
        <HeaderOne />
        <PageHeader title="Bedbug Extermination" name="Service" />
        <ServiceDetails />
        {/* <ContactTwo /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default ServiceDetailsPage;
