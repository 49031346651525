import React from "react";
import servicedetails from "@/images/services/service-details.jpg";
import Img from "./img";

const ServicePost = () => {
  return (
    <div className="service_details_left">
      <Img
        src={servicedetails}
        alt="Service Details Image"
        title="Service Details Image"
      />
      <h2 style={{ fontSize: "40px" }}>Bedbug Extermination</h2>
      <p>
        Bedbugs are quite widespread across the globe. Hong Kong is no exception
        this circumstance. They are known as among the major troublesome pests.
        There are several ways through which bedbugs can attack your home. It is
        fascinating that often we bring them home but we don't know about it.
        They can be brought to your living space by means of luggage, bags, or
        even clothes. What is more interesting is that only a single female
        bedbug is capable of quickly increasing their numbers at home.
        Mattresses and pillows are bedbugs’ favorite places.
      </p>
      <h3>Problems with Bedbugs</h3>
      <li>Bedbugs disperse throughout your home at a rapid pace.</li>
      <li>Personal belongings and furniture are their carrier.</li>
      <li>
        If they aren't maintained from time to time, they can spread all over.
      </li>
      <li>Bedbugs find our body heat appealing.</li>
      <li>They are skin-biting pests.</li>
      <li>DIY solutions aren’t effective against bedbugs.</li>
      <br />
      <h3>Way to Exterminate Them</h3>
      <li>
        Our technicians first take away all the pillows, sheets, covers, and
        cushions to gather them in a single place. Then they are boiled in water
        with a temperature over 50 degrees.
      </li>
      <li>
        Next, our team empties all your beds, tables, and closets where bedbugs
        have invaded and employ a hot steam device for spraying under high
        pressure.
      </li>
      <li>
        Lastly, our exterminators spray eco-conscious solutions on the surfaces
        and the bed items to remove all bedbugs.
      </li>
    </div>
  );
};

export default ServicePost;
